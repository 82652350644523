// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.highlight {
    color: #007bff;
	font-weight: 600;
}

.email {
    color: #007bff;
    text-decoration: underline;
}

input:disabled, textarea:disabled {
	-webkit-text-fill-color: #000; 
    -webkit-opacity: 1; 
	color: #000;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/common.css"],"names":[],"mappings":"AAAA;IACI,cAAc;CACjB,gBAAgB;AACjB;;AAEA;IACI,cAAc;IACd,0BAA0B;AAC9B;;AAEA;CACC,6BAA6B;IAC1B,kBAAkB;CACrB,WAAW;AACZ","sourcesContent":[".highlight {\n    color: #007bff;\n\tfont-weight: 600;\n}\n\n.email {\n    color: #007bff;\n    text-decoration: underline;\n}\n\ninput:disabled, textarea:disabled {\n\t-webkit-text-fill-color: #000; \n    -webkit-opacity: 1; \n\tcolor: #000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
