// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contactDetails .page-title {
  font-size: 24px; }

.contactDetails .sub-title {
  font-size: 16px; }

.contact-details-page {
  display: flex;
  border-bottom: 1px solid black;
  padding-bottom: 0.5rem; }
  .contact-details-page .name {
    font-size: 24px;
    margin-bottom: 0.5rem; }
  .contact-details-page .activity__left-content {
    width: 80%;
    padding: 5px; }
    .contact-details-page .activity__left-content label {
      font-weight: 600; }
    .contact-details-page .activity__left-content input {
      margin: 0;
      padding: 0px 0px 1px 0px;
      height: fit-content;
      border-color: black;
      margin-left: 1%; }
    .contact-details-page .activity__left-content .activity-page__search-input {
      display: flex;
      margin-bottom: 0.4rem; }
      .contact-details-page .activity__left-content .activity-page__search-input button {
        margin-left: 5%;
        border-radius: 10px; }
  .contact-details-page .activity__right-content {
    width: 20%;
    padding: 15px; }
    .contact-details-page .activity__right-content .activity-page_contact {
      color: #007bff;
      text-decoration: underline; }
  .contact-details-page p {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 0.5rem;
    padding: 0; }
  .contact-details-page span {
    font-size: 16px;
    font-weight: 600; }
  .contact-details-page .contact-page__list {
    margin-bottom: 1rem; }

.contact-page__back-link {
  margin: 5px; }
`, "",{"version":3,"sources":["webpack://./src/Pages/Contact/details.scss"],"names":[],"mappings":"AAAA;EAEE,eAAe,EAAA;;AAFjB;EAME,eAAe,EAAA;;AAIjB;EACC,aAAa;EACb,8BAA8B;EAC9B,sBAAsB,EAAA;EAHvB;IAKE,eAAe;IACf,qBAAqB,EAAA;EANvB;IASE,UAAU;IACV,YAAY,EAAA;IAVd;MAYG,gBAAgB,EAAA;IAZnB;MAgBG,SAAS;MACT,wBAAwB;MACxB,mBAAmB;MACnB,mBAAmB;MACnB,eAAe,EAAA;IApBlB;MAuBG,aAAa;MACb,qBAAqB,EAAA;MAxBxB;QA0BI,eAAe;QACf,mBAAmB,EAAA;EA3BvB;IAiCE,UAAU;IACV,aAAa,EAAA;IAlCf;MAoCG,cAAc;MACd,0BAA0B,EAAA;EArC7B;IA0CE,eAAe;IACf,gBAAgB;IAChB,SAAS;IACT,qBAAqB;IACrB,UAAU,EAAA;EA9CZ;IAkDE,eAAe;IACf,gBAAgB,EAAA;EAnDlB;IAuDE,mBAAmB,EAAA;;AAIrB;EACC,WAAW,EAAA","sourcesContent":[".contactDetails {\n\t.page-title {\n\t\tfont-size: 24px;\n\t}\n\n\t.sub-title {\n\t\tfont-size: 16px;\n\t}\n}\n\t\n.contact-details-page {\n\tdisplay: flex;\n\tborder-bottom: 1px solid black;\n\tpadding-bottom: 0.5rem;\n\t.name {\n\t\tfont-size: 24px;\n\t\tmargin-bottom: 0.5rem;\n\t}\n\t.activity__left-content {\n\t\twidth: 80%;\n\t\tpadding: 5px;\n\t\tlabel {\n\t\t\tfont-weight: 600;\n\t\t}\n\n\t\tinput {\n\t\t\tmargin: 0;\n\t\t\tpadding: 0px 0px 1px 0px;\n\t\t\theight: fit-content;\n\t\t\tborder-color: black;\n\t\t\tmargin-left: 1%;\n\t\t}\n\t\t.activity-page__search-input {\n\t\t\tdisplay: flex;\n\t\t\tmargin-bottom: 0.4rem;\n\t\t\tbutton {\n\t\t\t\tmargin-left: 5%;\n\t\t\t\tborder-radius: 10px;\n\t\t\t}\n\t\t}\n\t}\n\n\t.activity__right-content {\n\t\twidth: 20%;\n\t\tpadding: 15px;\n\t\t.activity-page_contact {\n\t\t\tcolor: #007bff;\n\t\t\ttext-decoration: underline;\n\t\t}\n\t}\n\n\tp {\n\t\tfont-size: 16px;\n\t\tfont-weight: 600;\n\t\tmargin: 0;\n\t\tmargin-bottom: 0.5rem;\n\t\tpadding: 0;\n\t}\n\n\tspan {\n\t\tfont-size: 16px;\n\t\tfont-weight: 600;\n\t}\n\n\t.contact-page__list {\n\t\tmargin-bottom: 1rem;\n\t}\n}\n\n.contact-page__back-link {\n\tmargin: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
