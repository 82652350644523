import React, { useState } from "react";
import Loader from "../../Components/Loader/Loader";
import DatePicker from "react-datepicker";
import { fetchActivities } from "../../Redux/Actions/Activity";
import { fetchOffices } from "../../Redux/Actions/Offices";
import { handleResponse } from "../../utils/Notification";
import { formatDate } from "../../utils/common";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "./activity.scss";
import { useQuery } from "react-query";

export const Activity = () => {
  const [selectedOffice, setSelectedOffice] = useState("");
  const [date, setDate] = useState(new Date());
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const queryParamsSelectedOffice = queryParams.get("selected_office");
  const queryParamsSelectedDate = queryParams.get("selected_date");

  const { data: offices } = useQuery(
    "offices",
    async () => await fetchOffices(),
    {
      initialData: [],
      refetchOnWindowFocus: false,
    }
  );

  const { data: activities, isFetching } = useQuery(
    ["activities", queryParamsSelectedOffice, queryParamsSelectedDate],
    async (_key) => {
      let params = {
        ...(queryParamsSelectedOffice && {
          office_id: queryParamsSelectedOffice,
        }),
        ...(queryParamsSelectedDate && {
          date: formatDate(new Date(queryParamsSelectedDate)),
        }),
      };
      queryParamsSelectedOffice && setSelectedOffice(queryParamsSelectedOffice);
      queryParamsSelectedDate && setDate(new Date(queryParamsSelectedDate));

      return await fetchActivities(params);
    },
    {
      placeholderData: [],
      refetchOnWindowFocus: false,
      onError: (error) => {
        handleResponse(error);
      },
    }
  );

  const handleSearch = async (e) => {
    e.preventDefault();
    history.push({
      pathname: "/",
      search: buildQueryParams(),
    });
  };

  const formatWorkOrder = (value = "") => {
    value = value ? value : "";
    const splittedValue = value.split("-");
    return splittedValue[splittedValue.length - 1];
  };

  const buildQueryParams = () => {
    return `?selected_office=${selectedOffice}&selected_date=${date}`;
  };

  const onSearchClicked = (e) => {
    e.preventDefault();
    history.push({
      pathname: "/",
      search: buildQueryParams(),
    });
  };

  const renderActivityItem = () => {
    // Filter out Storage types
    const filtered = activities?.data?.filter(item => item.activity_type !== "Storage")
    return filtered?.length > 0 ? (
      filtered.map((activity) => {
        return (
          <li key={activity.id}>
            <div className="activity_page-details">
              <div className="activity__left-content">
                <h6>
                  {activity.vehicle_model_name}
                </h6>
                <div className="activity__sub-details">
                  <span className="capitalize">
                    {activity.activity_type} - {activity.detail_type}
                  </span>
                  <br />
                  <span>{activity.alternate_to}</span>
                </div>
              </div>
              <div className="activity__right-content">
                <h6
                  style={{ cursor: "pointer" }}
                  className="activity-page_contact"
                  onClick={(e) =>
                    history.push({
                      pathname: `/activity/${activity.id}`,
                      search:
                        buildQueryParams() +
                        `&activity_id=${activity.activity_id}`,
                    })
                  }
                >
                  WO: {formatWorkOrder(activity.work_order_number)}
                </h6>
                <div className="activity__sub-details">
                  <span className="capitalize">{activity.gender || ""}</span>
                  <span><b>VIN: {activity.vin}</b></span>
                </div>
              </div>
            </div>
          </li>
        );
      })
    ) : (
      <li>No records found.</li>
    );
  };

  return (
    <div className="activity-page__container">
      <ul>
        <li>
          <strong>Activity</strong>
          <div className="activity_page-details">
            <form onSubmit={handleSearch} className="activity-page__form pb-2">
              <div className="row">
                <div className="col-8 d-flex pl-2">
                  <div className="col-3">
                    <label>Date:</label>
                  </div>
                  <div className="col-9 p-0">
                    <DatePicker
                      popperProps={{
                        positionFixed: true, // use this to make the popper position: fixed
                      }}
                      selected={date}
                      onChange={(date) => {
                        setDate(date);
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="col-4"></div>
              </div>
              <div className="row">
                <div className="col-8 d-flex pl-2">
                  <div className="col-3">
                    <label>Office:</label>
                  </div>

                  <div className="col-9 p-0">
                    <select
                      name="offices"
                      onChange={(e) => setSelectedOffice(e.target.value)}
                      value={selectedOffice}
                    >
                      <option></option>
                      {offices.data?.map((office) => {
                        return (
                          <option key={Math.random()} value={office.id}>
                            {office.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-4 p-0">
                  <button
                    onClick={onSearchClicked}
                    className="btn btn-sm btn-primary search-btn"
                  >
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </li>
        {isFetching && <Loader />}
        {renderActivityItem()}
      </ul>
    </div>
  );
};

export default Activity;
