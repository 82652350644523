// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.activity-page-edit__container .file-input {
  position: relative;
  overflow: hidden; }
  .activity-page-edit__container .file-input input {
    position: absolute;
    font-size: 50px;
    opacity: 0;
    right: 0;
    top: 0; }

.activity-page-edit__container select {
  width: 100%; }

.activity-page-edit__container table tr > td {
  border: none; }

.activity-page-edit__container .activity-page-edit__form .activity-contact {
  font-size: 1rem;
  font-weight: bold; }

.activity-page-edit__container .activity-page-edit__form p {
  margin: auto; }

.activity-page-edit__container .activity-page-edit__form input {
  height: 50%; }

.activity-page-edit__container .activity-page-edit__form input[type="checkbox"] {
  height: 25px;
  width: 25px; }

.activity-page-edit__container .activity-page-edit__form .col-4,
.activity-page-edit__container .activity-page-edit__form .col-8 {
  padding-right: 0; }

.activity-page-edit__container .activity-page-edit__form .form-check-input {
  margin-right: 0; }

.activity-page-edit__container .activity-page-edit__form .expense-field input {
  width: 100%;
  height: 50%; }

.activity-page-edit__container .activity-page-edit__form .expense-field input.receipt-upload {
  height: 100%; }

.activity-page-edit__container .activity-page-edit__form .cost-field input {
  width: 70%;
  height: 50%; }

.activity-page-edit__container .activity-page-edit__form .activity-page__receipts-container > div {
  padding: 15px; }
`, "",{"version":3,"sources":["webpack://./src/Pages/Activity/Edit/form.scss"],"names":[],"mappings":"AAAA;EAEI,kBAAkB;EAClB,gBAAgB,EAAA;EAHpB;IAMM,kBAAkB;IAClB,eAAe;IACf,UAAU;IACV,QAAQ;IACR,MAAM,EAAA;;AAVZ;EAeI,WAAW,EAAA;;AAff;EAmBI,YAAY,EAAA;;AAnBhB;EAwBM,eAAe;EACf,iBAAiB,EAAA;;AAzBvB;EA6BM,YAAY,EAAA;;AA7BlB;EAiCM,WAAW,EAAA;;AAjCjB;EAqCM,YAAY;EACZ,WAAW,EAAA;;AAtCjB;;EA0CM,gBAAgB,EAAA;;AA1CtB;EA8CM,eAAe,EAAA;;AA9CrB;EAmDQ,WAAW;EACX,WAAW,EAAA;;AApDnB;EAwDQ,YAAY,EAAA;;AAxDpB;EA8DQ,UAAU;EACV,WAAW,EAAA;;AA/DnB;EAoEM,aAAa,EAAA","sourcesContent":[".activity-page-edit__container {\n  .file-input {\n    position: relative;\n    overflow: hidden;\n\n    input {\n      position: absolute;\n      font-size: 50px;\n      opacity: 0;\n      right: 0;\n      top: 0;\n    }\n  }\n\n  select {\n    width: 100%;\n  }\n\n  table tr > td {\n    border: none;\n  }\n\n  .activity-page-edit__form {\n    .activity-contact {\n      font-size: 1rem;\n      font-weight: bold;\n    }\n\n    p {\n      margin: auto;\n    }\n\n    input {\n      height: 50%;\n    }\n\n    input[type=\"checkbox\"] {\n      height: 25px;\n      width: 25px;\n    }\n    .col-4,\n    .col-8 {\n      padding-right: 0;\n    }\n\n    .form-check-input {\n      margin-right: 0;\n    }\n\n    .expense-field {\n      input {\n        width: 100%;\n        height: 50%;\n      }\n\n      input.receipt-upload {\n        height: 100%;\n      }\n    }\n\n    .cost-field {\n      input {\n        width: 70%;\n        height: 50%;\n      }\n    }\n\n    .activity-page__receipts-container > div {\n      padding: 15px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
