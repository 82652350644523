// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topnav {
  overflow: hidden;
  background-color: #333;
  position: relative;
}

.topnav #myLinks {
  display: none;
}

.topnav a {
  text-decoration: none;
  background: black;
  color: white;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  display: block;
}

.topnav a.icon {
  background: black;
  display: block;
  position: absolute;
  top: 0;
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.active {
  background-color: black;
  color: white;
  min-height: 3.3rem;
}`, "",{"version":3,"sources":["webpack://./src/Components/Header/header-page.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,YAAY;EACZ,kBAAkB;EAClB,qBAAqB;EACrB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,kBAAkB;EAClB,MAAM;AACR;;AAEA;EACE,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".topnav {\n  overflow: hidden;\n  background-color: #333;\n  position: relative;\n}\n\n.topnav #myLinks {\n  display: none;\n}\n\n.topnav a {\n  text-decoration: none;\n  background: black;\n  color: white;\n  padding: 14px 16px;\n  text-decoration: none;\n  font-size: 17px;\n  display: block;\n}\n\n.topnav a.icon {\n  background: black;\n  display: block;\n  position: absolute;\n  top: 0;\n}\n\n.topnav a:hover {\n  background-color: #ddd;\n  color: black;\n}\n\n.active {\n  background-color: black;\n  color: white;\n  min-height: 3.3rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
