// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progressbar {
  position: fixed;
  width: 150px;
  height: 150px;
  z-index: 999;
  top: 50%;
  left: 50%;
  margin-top: -75px; /* Negative half of height. */
  margin-left: -75px; /* Negative half of width. */
  /* background: rgba(169, 41, 41, 0.7); */
}
`, "",{"version":3,"sources":["webpack://./src/Components/PercentageLoader/style.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,aAAa;EACb,YAAY;EACZ,QAAQ;EACR,SAAS;EACT,iBAAiB,EAAE,6BAA6B;EAChD,kBAAkB,EAAE,4BAA4B;EAChD,wCAAwC;AAC1C","sourcesContent":[".progressbar {\n  position: fixed;\n  width: 150px;\n  height: 150px;\n  z-index: 999;\n  top: 50%;\n  left: 50%;\n  margin-top: -75px; /* Negative half of height. */\n  margin-left: -75px; /* Negative half of width. */\n  /* background: rgba(169, 41, 41, 0.7); */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
