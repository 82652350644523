// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vehicle-page__back-link {
  margin: 5px; }

.vehichle-details span {
  font-weight: 600; }

.vehicle-container .activity__left-content {
  width: 100% !important; }

.vehicle-page__activity-details-list div.row {
  border-bottom: 1px solid black;
  padding: 10px 2px 10px 2px; }
  .vehicle-page__activity-details-list div.row p {
    margin: 0;
    font-size: 0.8rem;
    font-weight: 600; }
    .vehicle-page__activity-details-list div.row p.vin {
      color: #007bff;
      text-decoration: underline; }

.vehicle-page__activity-details-list .detail-item:nth-child(even) {
  background-color: #d3d3d3; }
`, "",{"version":3,"sources":["webpack://./src/Pages/Vehicle/Vehicle.scss"],"names":[],"mappings":"AAAA;EACE,WAAW,EAAA;;AAGb;EAEI,gBAAgB,EAAA;;AAGpB;EAEI,sBAAsB,EAAA;;AAI1B;EAEI,8BAA8B;EAC9B,0BAA0B,EAAA;EAH9B;IAKM,SAAS;IACT,iBAAiB;IACjB,gBAAgB,EAAA;IAPtB;MASQ,cAAc;MACd,0BAA0B,EAAA;;AAVlC;EAeI,yBAAyB,EAAA","sourcesContent":[".vehicle-page__back-link {\n  margin: 5px;\n}\n\n.vehichle-details {\n  span {\n    font-weight: 600;\n  }\n}\n.vehicle-container {\n  .activity__left-content {\n    width: 100% !important;\n  }\n}\n\n.vehicle-page__activity-details-list {\n  div.row {\n    border-bottom: 1px solid black;\n    padding: 10px 2px 10px 2px;\n    p {\n      margin: 0;\n      font-size: 0.8rem;\n      font-weight: 600;\n      &.vin {\n        color: #007bff;\n        text-decoration: underline;\n      }\n    }\n  }\n  .detail-item:nth-child(even) {\n    background-color: #d3d3d3;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
